<template>
  <div class="space-y-4">
    <article v-if="canCreateEBooklets">
      <h3 class="subheader">Quick Links</h3>
      <br />
      <section
        class="relative z-0 rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Create Fault E-booklet',
            })
          "
          icon="file-document"
          text="Create Fault Management Guidebook"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="pt-1">
      <asom-client-table
        v-if="!isLoading"
        :columns="tableColumns"
        :sortableColumns="['title', 'description', 'dateModified', 'remarks']"
        :data="tableData"
        :filterable="false"
      >
        <template v-slot:dateModified="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
          }}</span>
        </template>
        <template v-slot:remarks="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
        </template>
        <template v-slot:title="scopedSlots">
          <button
            v-if="canViewEBooklets"
            @click="navigateToView(scopedSlots.rowData)"
            class="text-button underline font-semibold"
          >
            {{ scopedSlots.data }}
          </button>
          <div v-else>
            {{ scopedSlots.data }}
          </div>
        </template>
        <template v-slot:description="scopedSlots">
          <span class="overflow-ellipsis line-clamp-2">{{scopedSlots.data}}</span>
        </template>
        <template v-slot:action="scopedSlots">
          <div class="flex flex-col gap-1 w-48">
            <asom-button
              v-if="canEditEBooklets"
              size="sm"
              outline
              text="Delete"
              variant="error"
              @click="deleteClicked(scopedSlots.rowData)"
            />
            <asom-button
              v-if="canEditEBooklets"
              size="sm"
              outline
              text="Update"
              @click="navigateToUpdate(scopedSlots.rowData)"
            />
          </div>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
    <asom-modal
      title="Delete Fault E-Booklet"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse">
        <div class="pl-4">
          <asom-button @click="onDelete" text="OK" :loading="isSubmitting" />
        </div>
        <div>
          <asom-button
            @click="toggleModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import {
  getListOfFaultEBooklets,
  deleteFaultEBooklet,
} from "../../../services/faultManagement.service";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";

export default {
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      faultOccurrenceList: [],
      error: null,
      selectedItem: null,
      showModal: false,
    };
  },
  mounted() {
    this.getPageData();
  },
  computed: {
    ...mapGetters({
      canCreateEBooklets: "auth/canCreateEBooklets",
      canEditEBooklets: "auth/canEditEBooklets",
      canViewEBooklets: "auth/canViewEBooklets",
      selectedLineId: "selectedStation/lineId"
    }),
    tableData() {
      return this.faultOccurrenceList;
    },
    tableColumns() {
      let columns = ["title", "description", "dateModified", "remarks"];
      if (this.canEditEBooklets) columns.push("action");
      return columns;
    },
  },
  methods: {
    displayUtcDate,
    navigateToUpdate(selectedItem) {
      this.$router.push({
        name: "Edit Fault E-booklet",
        params: selectedItem,
      });
    },
    navigateToView(selectedItem) {
      this.$router.push({
        name: "View Fault E-booklet",
        params: selectedItem,
      });
    },
    async getPageData() {
      this.isLoading = true;
      const lineId = this.selectedLineId;
      const resp = await getListOfFaultEBooklets({
        skip: 0,
        take: 100,
        lineId
      });
      if (resp.success) {
        this.faultOccurrenceList = get(resp.payload, "list", []);
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    deleteClicked(selectedItem) {
      this.selectedItem = selectedItem;
      this.toggleModal(true);
    },
    toggleModal(value) {
      this.showModal = value;
    },
    async onDelete() {
      this.isSubmitting = true;
      const result = await deleteFaultEBooklet({
        faultEBookletId: get(this.selectedItem, "faultEBookletId"),
      });
      if (result.success) {
        this.isSubmitting = false;
        this.toggleModal(false);
        this.getPageData();
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.toggleModal(false);
        this.$scrollTop();
      }
    },
  },
};
</script>
